.popover-dialog{
    li {
        border-bottom: 2px solid #ededed;
        span{
            margin: 0;
            font-size: 0.875rem;
        }
        &:hover{
            background: none;
        }
    }
}

.search-container{
    border-bottom: 2px solid #ededed;
    padding: 0 0 10px 0;
}

.listingFilter-Dropdown{
    white-space: break-spaces;
}
