/* ============== Primary Colors ======================== */

$primary_purple: #402080;
$primary_black: #191d23;
$primary_yellow: #ffbf00;

/* Primary-Purple Color Shades */

$primary_purple-light: #ece9f2;
$primary_purple-light-hover: #e2deec;
$primary_purple-light-active: #c4bad8;

$primary_purple-normal: #402080;
$primary_purple-normal-hover: #3a1d73;
$primary_purple-normal-active: #331a66;

$primary_purple-dark: #301860;
$primary_purple-dark-hover: #26134d;
$primary_purple-dark-active: #1d0e3a;

$primary_purple-darker: #160b2d;

/* Primary-Black Color Shades */

$primary_black-light: #e8e8e9;
$primary_black-light-hover: #ddddde;
$primary_black-light-active: #b8b9bb;

$primary_black-normal: #191d23;
$primary_black-normal-hover: #171a20;
$primary_black-normal-active: #14171c;

$primary_black-dark: #13161a;
$primary_black-dark-hover: #0f1115;
$primary_black-dark-active: #0b0d10;

$primary_black-darker: #090a0c;

/* ============== Primary Colors ========================== */

/* ============== Secondary Colors ======================== */

$secondary_dark-grey: #383f43;
$secondary_light-grey: #b1b4b6;
$secondary_link-blue: #24bff3;
$dark_grey-shade: #111827;

/* Light Grey Color Shades */

$secondary_lgrey-light: #f7f8f8;
$secondary_lgrey-light-50OPAC: #f7f8f880;

$secondary_lgrey-light-hover: #f3f4f4;
$secondary_lgrey-light-active: #e7e8e8;

$secondary_lgrey-normal: #b1b4b6;
$secondary_lgrey-normal-hover: #9fa2a4;
$secondary_lgrey-normal-active: #8e9092;

$secondary_lgrey-dark: #858789;
$secondary_lgrey-dark-hover: #6a6c6d;
$secondary_lgrey-dark-active: #505152;

$secondary_lgrey-darker: #3e3f40;

/* ============== Secondary Colors ======================== */

/* ============== Accent Colors ======================== */

$accent_red: #d4351c;
$accent_green: #005a30;
$accent_yellow: #ffb300;

/* Accent Red Color Shades */

$accent_red-light: #fbebe8;
$accent_red-light-hover: #f9e1dd;
$accent_red-light-active: #f2c0b9;

$accent_red-normal: #d4351c;
$accent_red-normal-hover: #bf3019;
$accent_red-normal-active: #aa2a16;

$accent_red-dark: #9f2815;
$accent_red-dark-hover: #7f2011;
$accent_red-dark-active: #5f180d;

$accent_red-darker: #4a130a;

/* Accent Green Color Shades */

$accent_green-light: #e6efea;
$accent_green-light-hover: #d9e6e0;
$accent_green-light-active: #b0ccbf;

$accent_green-normal: #005a30;
$accent_green-normal-hover: #00512b;
$accent_green-normal-active: #004826;

$accent_green-dark: #004424;
$accent_green-dark-hover: #00361d;
$accent_green-dark-active: #002816;

$accent_green-darker: #001f11;

/* Accent Yellow Color Shades */

$accent_yellow-light: #fff7e6;
$accent_yellow-light-hover: #fff4d9;
$accent_yellow-light-active: #ffe7b0;

$accent_yellow-normal: #ffb300;
$accent_yellow-normal-hover: #e6a100;
$accent_yellow-normal-active: #cc8f00;

$accent_yellow-dark: #bf8600;
$accent_yellow-dark-hover: #996b00;
$accent_yellow-dark-active: #735100;

$accent_yellow-darker: #593f00;

/* ============== Accent Colors ======================== */

$white: #ffffff;

$border_grey: #f5f4f4;
$border-bottom-grey: #f0f0f0;
$light_grey_bg: #f3f2f1;
/* All Colors assigned to color code for specific colors. */

$colours: (
  'col1' $primary_purple,
  'col2' $primary_black,
  'col3' $primary_yellow,
  'col4' $primary_purple-light,
  'col5' $primary_purple-light-hover,
  'col6' $primary_purple-light-active,
  'col7' $primary_purple-normal,
  'col8' $primary_purple-normal-hover,
  'col9' $primary_purple-normal-active,
  'col10' $primary_purple-dark,
  'col11' $primary_purple-dark-hover,
  'col12' $primary_purple-dark-active,
  'col13' $primary_purple-darker,
  'col14' $primary_black-light,
  'col15' $primary_black-light-hover,
  'col16' $primary_black-light-active,
  'col17' $primary_black-normal,
  'col18' $primary_black-normal-hover,
  'col19' $primary_black-normal-active,
  'col20' $primary_black-dark,
  'col21' $primary_black-dark-hover,
  'col22' $primary_black-dark-active,
  'col23' $primary_black-darker,
  'col24' $secondary_dark-grey,
  'col25' $secondary_light-grey,
  'col26' $secondary_link-blue,
  'col27' $secondary_lgrey-light,
  'col28' $secondary_lgrey-light-hover,
  'col29' $secondary_lgrey-light-active,
  'col30' $secondary_lgrey-normal,
  'col31' $secondary_lgrey-normal-hover,
  'col32' $secondary_lgrey-normal-active,
  'col33' $secondary_lgrey-dark,
  'col34' $secondary_lgrey-dark-hover,
  'col35' $secondary_lgrey-dark-active,
  'col36' $secondary_lgrey-darker,
  'col37' $accent_red,
  'col38' $accent_green,
  'col39' $accent_yellow,
  'col40' $accent_red-light,
  'col41' $accent_red-light-hover,
  'col42' $accent_red-light-active,
  'col43' $accent_red-normal,
  'col44' $accent_red-normal-hover,
  'col45' $accent_red-normal-active,
  'col46' $accent_red-dark,
  'col47' $accent_red-dark-hover,
  'col48' $accent_red-dark-active,
  'col49' $accent_red-darker,
  'col50' $accent_green-light,
  'col51' $accent_green-light-hover,
  'col52' $accent_green-light-active,
  'col53' $accent_green-normal,
  'col54' $accent_green-normal-hover,
  'col55' $accent_green-normal-active,
  'col56' $accent_green-dark,
  'col57' $accent_green-dark-hover,
  'col58' $accent_green-dark-active,
  'col59' $accent_green-darker,
  'col60' $accent_yellow-light,
  'col61' $accent_yellow-light-hover,
  'col62' $accent_yellow-light-active,
  'col63' $accent_yellow-normal,
  'col64' $accent_yellow-normal-hover,
  'col65' $accent_yellow-normal-active,
  'col66' $accent_yellow-dark,
  'col67' $accent_yellow-dark-hover,
  'col68' $accent_yellow-dark-active,
  'col69' $accent_yellow-darker,
  'col70' $white,
  'col71' $secondary_lgrey-light-50OPAC,
  'col72' $border_grey,
  'col73' $dark_grey-shade,
  'col74' $border-bottom-grey,
  'col75' $light_grey_bg
);

/* Defining classes for color and background-color with respect to color codes */
/* for color class use class as 'col1', 'col2' and etc */
/* for background-color use class as 'bg-col1', 'bg-col2' and etc */

@each $i in $colours {
  .#{nth($i, 1)} {
    color: nth($i, 2) !important;
  }
  .bg-#{nth($i, 1)} {
    background-color: nth($i, 2);
  }
}
