.sidebar-list {
  position: relative;
  padding-bottom: 30px !important;
  font-family: Inter, sans-serif !important;
  span {
    font-family: Inter, sans-serif !important;
  }
  li:first-child {
    margin-top: 20px !important;
  }
  li:last-child {
    position: relative;
    &::after {
      content: '';
      height: 1px;
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: -30px;
      background-color: #d0d5dd;
    }
  }
}
.sidebar-footer-main {
  position: absolute;
  left: 30px;
  bottom: 4%;
  h6 {
    color: #383f43;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  p {
    overflow: hidden !important;
    color: #6a6c6d;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
.open-sidebar {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}
