.root {
  // font-size: 26px;
  // font-weight: 600;
  // background-color: #f5f4f4;
}

#root {
  max-height: 100%;
  overflow-y: scroll;
}

.loader {
  position: absolute;
  height: calc(100vh - 60px);
  width: calc(100vw);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &.page {
    position: static;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
body > div > div > .root .loader {
  z-index: 1350;
}
.capitalize {
  text-transform: capitalize;
}
ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root {
  &:after {
    height: calc(100% - 80px - 6px);
  }
}

.listingLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.custom-commonTable tr th,
.custom-commonTable tr td {
  min-width: 220px;
  max-width: 220px;
  &:has(.list_clone_icon) {
    max-width: 56px;
    min-width: 56px;
  }
  &:has(.list_version_icon) {
    max-width: 56px;
    min-width: 56px;
  }
  &:has(.list_clone_version_icon) {
    max-width: 112px;
    min-width: 112px;
  }
  &:has(.no_icon) {
    max-width: 0px;
    min-width: 0px;
  }

  &:has(.list_image) {
    max-width: 64px;
    min-width: 64px;
  }
  &:has(.list_asset_id) {
    max-width: 120px;
    min-width: 120px;
  }
}
.statusActive {
  display: inline-flex !important;
  height: 24px !important;
  width: auto !important;
  padding: 0px 9px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  color: #005a30 !important;
  background-color: #e6efea !important;
  font-family: Inter, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border-radius: 4px !important;
}
.statusInactive {
  display: inline-flex !important;
  height: 24px !important;
  width: auto !important;
  padding: 0px 9px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  color: #3e3f40 !important;
  background-color: #ddddde !important;
  font-family: Inter, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border-radius: 4px !important;
}
.custom-commonTable .customRowClickable + .innerOpenedRow .custom-td-child {
  min-width: 220px;
  max-width: 220px;
  &:nth-child(1) {
    background-color: #f5f4f4;
    border: none;
  }
  &:has(.list_clone_icon) {
    max-width: 56px;
    min-width: 56px;
  }
  &:has(.no_icon) {
    max-width: 0px;
    min-width: 0px;
  }
  &:has(.list_clone_version_icon) {
    max-width: 112px;
    min-width: 112px;
    padding-left: 28px;
    & div {
      overflow: visible;
    }
  }
  &:has(.list_version_icon) {
    max-width: 56px;
    min-width: 56px;
    padding-left: 30px;
    & div {
      overflow: visible;
    }
    span.dashLine {
      width: 28px;
    }
  }
  &:has(.list_image) {
    max-width: 64px;
    min-width: 64px;
  }
  &:has(.list_asset_id) {
    max-width: 120px;
    min-width: 120px;
  }
}
.app-accordian {
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
  .MuiButtonBase-root .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
}