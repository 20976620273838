.contentState {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  padding: 4px 9px;
  border-radius: 4px;
  // padding: 0px, 9px, 0px, 9px;
  color: #0073a1;
  background-color: #e6f7fd;
  &:empty{
    padding: 0;
  }
}
.draft,
.draftinprogress {
  color: #9f760d;
  background-color: #ffefd1;
}
.draftcomplete {
  color: #5c4200;
  background-color: #f4e7c8;
}
.qc_passed,
.qc_failed,
.qc_by_pass,
.qc_in_progress,
.draft_under_qc {
  color: #402080;
  background-color: #ece9f2;
}
.readyforfeaturing {
  color: #160b2d;
  background-color: #d3cce4;
}
.created,
.schedulefeature {
  color: #0073a1;
  background-color: #e6f7fd;
}
.featured {
  color: #005a30;
  background-color: #e6efea;
}
.rightsexpired {
  color: #d4351c;
  background-color: #f9e1dd;
}
.unfeatured {
  background-color: #f2c0b9;
  color: #4a130a;
}
.failed,
.publish_fail,
.archieved,
.de_publish,
.depublish,
.deleted {
  color: #d4351c;
  background-color: #f9e1dd;
}
