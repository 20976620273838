$primary-font: Inter, sans-serif;

$letter-spacing: 0%;

$font-medium-bold: 300;
$font-semi-bold: 500;
$font-weight-400: 400;
$font-bold: 600;
$font-weight-700: 700;

$heading-1: 32px;
$heading-2: 24px;
$heading-3: 20px;
$heading-4: 18px;
$heading-5: 16px;
$heading-6: 14px;

$heading-height-1: 42px;
$heading-height-2: 34px;
$heading-height-3: 30px;
$heading-height-4: 28px;
$heading-height-5: 26px;
$heading-height-6: 24px;

$paragraph-1: 24px;
$paragraph-2: 20px;
$paragraph-3: 18px;
$paragraph-4: 16px;
$paragraph-5: 14px;
$paragraph-6: 12px;

$paragraph-height-1: 30px;
$paragraph-height-2: 28px;
$paragraph-height-3: 26px;
$paragraph-height-4: 28px;
$paragraph-height-5: 26px;
$paragraph-height-6: 24px;

.font-normal {
  font-weight: $font-weight-400;
}

.font-semibold {
  font-weight: $font-semi-bold;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-copy {
  cursor: copy;
}

.pointer-events-none {
  pointer-events: none;
}

.font-600 {
  font-weight: $font-bold;
}
