.common_status_css {
  height: Hug (24px);
  padding: 0px, 9px, 0px, 9px !important;
  border-radius: 4px;
  gap: 10px;
}
.common_markDOne_css {
  height: Hug (26px);
  top: 261px;
  left: 990px;
  padding: 0 6px;
  border-radius: 4px;
  gap: 10px;
  font-size: 12px !important;
  font-weight: 500 !important;
  svg {
    font-size: 1rem;
  }
}
.common_triangle_css {
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 15px solid #005a30;
  border-bottom: 12px solid transparent;
  position: absolute;
  top: 7.5rem;
  right: 0.5rem;
}
.common_hr_lines_psuedo_css {
  content: ' ';
  height: 2px;
  width: 130px;
  background: #e7e8e8;
  display: block;
  position: absolute;
  top: 50%;
}
.draft {
  @extend .common_status_css;
  width: Hug (47px);
  background-color: #fff7e6;
  color: #cc8f00;
  font-size: 12px;
}
.published {
  @extend .common_status_css;
  width: Hug (74px);
  color: #005a30;
  background-color: #e6efea;
}
.schedule {
  @extend .common_status_css;
  width: Hug (162px);
  background-color: #e6f7fd;
  color: #0073a1;
}
.failed {
  @extend .common_status_css;
  width: Hug (162px);
  background-color: #f9e1dd;
  color: #d4351c;
}
.markDone {
  @extend .common_markDOne_css;
  background-color: #e8e8e9;
  color: #9fa2a4;
}
.markDoneActive {
  @extend .common_markDOne_css;
  border: 0.75px solid var(--Accents_Green-Green, #005a30);
  color: #005a30;
}
.card {
  background-color: #e74c3c; /* Change this to the desired color for your card */
  width: 150px; /* Adjust the width of your card */
  height: 100px; /* Adjust the height of your card */
  position: absolute;
  top: 10px; /* Adjust the distance from the top of the header */
  right: 10px; /* Adjust the distance from the right of the header */
  border-radius: 8px; /* Optional: Add rounded corners to the card */
  color: #fff; /* Optional: Set text color */
  padding: 10px; /* Optional: Add padding to the card */
}

.triangleInright {
  @extend .common_triangle_css;
  border-left: 15px solid #005a30;
}
.triangleOutright {
  @extend .common_triangle_css;
  border-left: 15px solid #e74c3c;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}

.hr__lines {
  position: relative;
  margin: 24px 120px;
  text-align: center;
}

.hr__lines:before {
  @extend .common_hr_lines_psuedo_css;
  left: 0;
}

.hr__lines:after {
  @extend .common_hr_lines_psuedo_css;
  right: 0;
}

.form__div_height {
  max-height: calc(100vh - 220px);
  overflow: auto;
  padding: 24px 16px;
}

.form__div_action_button {
  padding: 24px 16px;
}
.f_12_w_600 {
  font-size: 12px;
  font-weight: 600;
}
.w_600 {
  font-weight: 600;
}
.header_value {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.asset_header_static {
  padding: 18px 16px 18px 16px;
  background-color: #ffff;
  font-size: 14px;
  border-radius: 8px;
  flex-wrap: nowrap;
}
.asset_header_dynamic_bottom {
  padding: 7px 16px 7px 16px;
  background-color: #f7f8f8;
  font-size: 14px;
  border-radius: 0px, 0px, 8px, 8px;
}
.preview_image_download_btn {
  line-height: 1.5 !important;
  padding: 10px 0 !important;
  border-radius: 0.5rem !important;
}