.headerDataWrapper {
    display: flex;
    align-items: baseline;
    margin: 2px 0;

    &:first-child {
            font-weight: 400;
            margin-right: 0.25rem;
            white-space: nowrap;
        }
}

.deleteIcon {
    position: absolute;
    right: 21px;
    bottom: 14px;
    cursor: pointer;
}


.widgetHeaderRow{
    position: relative;
    .widgetHeaderCol{
        width: 33.33333333%;
    }

    .accorionIcon{
        position: absolute;
        top: 19px;
        right: 21px;
    }

    &.AccordionOpen{
        border-radius: 8px 8px 0 0;
    }
}

.widgetHeaderTitle {
    font-weight: 400;
    min-width: fit-content;
}
.widgetTitle {
    padding-right: 1.5rem;
    gap: 10px;
}