@import '../../../Assets/Styles/colors.scss';

.dashboard-card {
  min-height: 100px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;

  .title {
    color: $secondary_lgrey-dark-hover;
  }
}

.color0 {
  background-color: #ffefd1;
}
.color1 {
  background-color: #d3cce4;
}
.color2 {
  background-color: $accent_green-light;
}
.color3 {
  background-color: $accent_red-light-hover;
}
.color4 {
  background-color: #e6f7fd;
}
.color5 {
  background-color: $primary_purple-light;
}

.icon {
  background-color: white;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
