@import '../../../Assets/Styles/colors.scss';

.alert {
  display: flex;

  padding: 12px;
  align-items: center;
  gap: 8px;
  text-align: left;
  flex-shrink: 0;
  border-radius: 8px;
  &.error {
    background: $accent_red-light;
    color: $accent_red;
  }
  &.warning {
    background: $accent_yellow-light;
    color: $accent_yellow;
  }
  &.success {
    background: $accent_green-light;
    color: $accent_green;
  }
}
