.statusActive {
  color: #005a30;
  background-color: #e6efea;
  height: 2rem;
  width: 5.6rem;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px !important;
  text-align: center;
  border-radius: 4px;
}
.statusInactive {
  color: 'col36';
  background-color: #ece9f2;
  height: 2rem;
  width: 5.6rem;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px !important;
  text-align: center;
  border-radius: 4px;
}

.mastersListWrapper {
  margin-top: 10px;
}
