// Imported colors.scss file into index.scss to globally use it in project

@import './Assets/Styles/colors.scss';
@import './Assets/Styles/typography.scss';
@import './Assets/Styles/gridspacing.scss';

html,
body {
  background-color: #f5f4f4 !important;
  font-family: $primary-font !important;
  letter-spacing: $letter-spacing !important;
  height: 100%;
  width: 100%;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  font-family: $primary-font !important;
}
* {
  margin: 0;
  padding: 0;

  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(179, 179, 179);
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #d9d9d9;

  border-radius: 10px;
}

.interFont {
  font-family: Inter, sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

// heading
.heading-1 {
  font-size: $heading-1;
  font-weight: $font-bold;
  line-height: $heading-height-1;
}

.heading-2 {
  font-size: $heading-2;
  font-weight: $font-bold;
  line-height: $heading-height-2;
}

.heading-3 {
  font-size: $heading-3;
  font-weight: $font-bold;
  line-height: $heading-height-3;
}

.heading-4 {
  font-size: $heading-4;
  font-weight: $font-bold;
  line-height: $heading-height-4;
}

.heading-5 {
  font-size: $heading-5 !important;
  font-weight: $font-bold !important;
  line-height: $heading-height-5 !important;
}

.heading-6 {
  font-size: $heading-6;
  font-weight: $font-bold;
  line-height: $heading-height-6;
  text-transform: capitalize;
}

.heading-7 {
  font-size: $heading-6;
  font-weight: $font-weight-400;
  line-height: $heading-height-5;
}

// sub-heading
.sub-heading-1 {
  font-size: $heading-1;
  font-weight: $font-semi-bold;
  line-height: $heading-height-1;
}

.sub-heading-2 {
  font-size: $heading-2;
  font-weight: $font-semi-bold;
  line-height: $heading-height-2;
}

.sub-heading-3 {
  font-size: $heading-3;
  font-weight: $font-semi-bold;
  line-height: $heading-height-3;
}

.sub-heading-4 {
  font-size: $heading-4;
  font-weight: $font-semi-bold;
  line-height: $heading-height-4;
}

.sub-heading-5 {
  font-size: $heading-5;
  font-weight: $font-semi-bold;
  line-height: $heading-height-5;
}

.sub-heading-6 {
  font-size: $heading-6;
  font-weight: $font-semi-bold;
  line-height: $heading-height-6;
}

.sub-heading-7 {
  font-size: $heading-6;
  font-weight: $font-semi-bold;
  line-height: $heading-height-5;
}

// paragraph-bold
.paragraph-bold-1 {
  font-size: $paragraph-1;
  font-weight: $font-medium-bold;
  line-height: $paragraph-height-1;
}

.paragraph-bold-2 {
  font-size: $paragraph-2;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-2;
}

.paragraph-bold-3 {
  font-size: $paragraph-3;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-3;
}

.paragraph-bold-4 {
  font-size: $paragraph-4;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-4;
}

.paragraph-bold-5 {
  font-size: $paragraph-5;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-5;
}

.paragraph-bold-6 {
  font-size: $paragraph-6;
  font-weight: $font-bold;
  line-height: $paragraph-height-6;
}

.paragraph-bold-7 {
  font-weight: $font-weight-700;
  font-size: $heading-3;
}

.font-medium {
  font-weight: $font-semi-bold !important;
}

// paragraph-regular
.paragraph-regular-1 {
  font-size: $paragraph-1;
  font-weight: normal;
  line-height: $paragraph-height-1;
}

.paragraph-regular-2 {
  font-size: $paragraph-2;
  font-weight: normal;
  line-height: $paragraph-height-2;
}

.paragraph-regular-3 {
  font-size: $paragraph-3;
  font-weight: normal;
  line-height: $paragraph-height-3;
}

.paragraph-regular-4 {
  font-size: $paragraph-4;
  font-weight: normal;
  line-height: $paragraph-height-4;
}

.paragraph-regular-5 {
  font-size: $paragraph-5;
  font-weight: normal;
  line-height: $paragraph-height-5;
}

.paragraph-regular-6 {
  font-size: $paragraph-6;
  font-weight: normal;
  line-height: $paragraph-height-6;
}

.text-left {
  text-align: left;
}

.font-12 > p {
  font-size: 12px;
}

.word-break-warp {
  overflow: hidden;
  word-break: break-word;
}

.word-break-all {
  word-break: break-all;
}

.width-fill-available {
  max-width: -webkit-fill-available;
}

.gap-2 {
  gap: 2px;
}

.gap-12 {
  gap: 12px;
}

// Breadcrums Styling

.breadcrums__link {
  text-decoration: none;
  color: $secondary_lgrey-dark-active;
  margin-right: 5px;
  font-size: $paragraph-6;
  font-weight: 400;

  &.active {
    color: $primary_purple;
  }
}

//icon-styling

.primary__circle-icon {
  margin-right: 10px;
}

.circle__icon-wrapper {
  font-size: 20px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  border-radius: 50%;

  &.error {
    background-color: $accent_red-light;
    color: $accent_red;
  }

  &.success {
    background-color: $accent_green-light;
    color: $accent_green;
  }

  &.primary {
    background-color: $primary_purple-light;
    color: $primary_purple;
  }

  &.warning {
    background-color: $accent_yellow-light;
    color: $accent_yellow-normal;
  }

  .circle__icon-inner {
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 50%;

    &.error {
      background-color: $accent_red-light-hover;
    }

    &.warning {
      background-color: $accent_yellow-light-active;
    }

    &.success {
      background-color: $accent_green-light-hover;
    }

    &.primary {
      background-color: $primary_purple-light-hover;
    }

    svg {
      font-size: 30px;
    }
  }
}

// custom-chip buttons

.chip__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  height: 24px;
  border-radius: 4px;
  gap: 6px;
  max-width: fit-content;
  cursor: pointer;
  font-size: 12px !important;
  line-height: 24px;

  &.primary {
    background-color: $primary_purple-light;
    color: $primary_purple;
  }

  &.danger {
    background-color: $accent_red-light;
    color: $accent_red;
  }

  &.success {
    background-color: $accent_green-light;
    color: $accent_green;
  }

  &.secondary {
    background-color: $primary_black-light;
    color: $secondary_lgrey-darker;
    position: relative !important;
  }

  .chip__button-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 18px;
    }
  }

  .chip__button-text {
    font-weight: normal;
    font-size: $paragraph-6;
  }
}

//modal index style
div.MuiDrawer-modal {
  z-index: 9999;
}

.whitespace__pre {
  white-space: pre;
}

.whitespace__preline {
  white-space: pre-line;
}

.whitespace__nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.font-bold {
  font-weight: bold;
}

.text__capitalcase {
  text-transform: capitalize !important;
}

.pb-120 {
  padding-bottom: 120px;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.position-relative {
  position: relative !important;
}

.login-screen-layout {
  margin: 0 !important;
  width: 100% !important;
  overflow: hidden !important;
}

.login-screen-content {
  position: absolute;
  bottom: 90px;
  color: #fff;
  width: 582px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;

  h3 {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 150% */
    color: #fff;
    margin-bottom: 15px;
  }

  p {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 200% */
    color: #fff;
  }
}

.text-inter {
  font-family: Inter, sans-serif !important;

  &.active {
    svg * {
      fill: #402080;
    }
  }
}

.c-input.listing-search {
  width: 289px;
  input {
    min-width: 204px;
    padding-left: 0 !important;
  }

  .MuiOutlinedInput-root {
    width: -webkit-fill-available;
  }
}

.c-input.listing-search.large {
  width: 320px;
}

.c-input {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  height: 48px !important;
  border-radius: 6px !important;
  background-color: #fff !important;

  div {
    height: 100% !important;
  }

  ::placeholder {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #545454 !important;
  }
}

.c-input-br-6 {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  height: 48px !important;
  border-radius: 6px !important;
  background-color: #fff !important;

  div {
    height: 100% !important;
    border-radius: 6px !important;
  }

  ::placeholder {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #545454 !important;
  }
}

.c-btn {
  height: 48px !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  padding: 12px, 16px, 12px, 16px !important;
  width: auto !important;
  min-width: 160px !important;
}

.c-table {
  table {
    th {
      padding: 8px 12px !important;
      font-family: Inter, sans-serif !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 24px !important;
      white-space: nowrap !important;
    }

    td {
      padding: 12px;
      font-family: Inter, sans-serif !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      white-space: nowrap !important;
    }
  }
}

.c-pagination {
  p {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    color: #3e3f40 !important;
    display: inline-block !important;
  }

  .MuiPaginationItem-page.Mui-selected {
    border-radius: 0 !important;
  }

  nav {
    border-radius: 6px !important;
  }
}
.c-drop-down-pagination {
  div {
    padding: 10.5px 14px !important;
    font-family: Inter, sans-serif;
  }
}

.input-label-class.MuiFormLabel-root {
  top: -5px;
}

.input-label-class.MuiFormLabel-root.MuiInputLabel-shrink {
  top: 0;
}

.font__inter {
  font-family: $primary-font !important;
}

.listing__title {
  color: #111827 !important;
  font-family: $primary-font !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
}

.status__active {
  font-family: $primary-font !important;
  color: #005a30 !important;
  background-color: #e6efea !important;
  height: 24px !important;
  width: 54px !important;
  padding: 0 9px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  line-height: 24px !important;
}

.status__inactive {
  font-family: $primary-font !important;
  background-color: #ddddde !important;
  height: 24px !important;
  width: 69px !important;
  padding: 0 9px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  line-height: 24px !important;
  color: #3e3f40 !important;
}

.full__height {
  height: 100vh;
}

.associated-users-custom-table > div {
  padding: 15px !important;
}

.associated-users-custom-table > div > div > div {
  margin: 0 !important;
}

.associated-users-custom-table {
  .c-pagination {
    margin-top: 30px !important;
  }
}

.associated-users-count {
  display: inline-flex;
  align-items: center;
  padding-left: 16px;

  h6,
  p {
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  h6 {
    color: #6a6c6d;
    margin-right: 2rem;
  }

  p {
    color: #3e3f40;
  }
}

.font-12p {
  font-size: 12px;
}

.c-sort {
  .MuiPaper-rounded {
    box-shadow: 0px 20px 24px -4px rgba(56, 63, 67, 0.08) !important;
    border-radius: 12px;
    width: 174px;
    padding: 12px;

    .MuiTypography-root {
      font-size: 16px;
      color: rgba(25, 29, 35, 1);
      font-weight: 600;
    }

    .MuiFormGroup-root {
      gap: 8px;
      margin-top: 12px;

      li {
        padding: 0;

        label {
          margin: 0;
          padding: 6px 0;
          display: flex;
          justify-content: space-between;
          width: 100%;

          .ml-8p {
            margin-left: 8px;
          }

          span {
            padding: 0;
          }
        }
      }
    }
  }
}

.c-sort.common-table-filter-date {
  .MuiPaper-rounded {
    width: 265px;
  }
}

.content-open-layout {
  width: 100%;
  padding-left: 299px !important;
}

.content-close-layout {
  width: 100%;
  padding-left: 64px !important;
}

.c-modal {
  padding: 24px !important;

  h2 {
    display: inline-flex !important;
    margin-bottom: 20px;
  }

  div {
    .pt-1raph-bold-3 {
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #191d23;
      margin-bottom: 12px;
    }

    .paragraph-regular-5 {
      margin-bottom: 32px;
    }
  }

  .d-block {
    display: block;
  }

  .dialog-btns {
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: nowrap;

    .cancel-action-btn {
      flex-basis: 50%;

      &.w-100 {
        flex-basis: 100%;
      }
    }

    .accept-action-btn {
      flex-basis: 50%;
    }

    button {
      display: inline-flex;
      height: 44px;
      width: 170px !important;
      border-radius: 8px;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      &:hover {
        background-color: none !important;
      }
    }

    .cancel-action-btn button {
      border: 1px solid #d0d5dd;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .accept-action-btn {
      &:hover {
        opacity: 0.9 !important;
      }
    }
  }

  h2,
  > div {
    padding: 0 !important;
  }

  .css-knqc4i-MuiDialogActions-root {
    padding: 0 !important;
  }
}

.c-active-inactive-dropdown > div {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.c-active-inactive-dropdown > div:hover > ul {
  background-color: #eee;
}

.c-active-inactive-dropdown > div > ul {
  padding: 0 !important;
}

.c-active-inactive-dropdown > div > ul > li {
  font-size: 14px !important;
  padding-right: 15px;
  padding-left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;

  &.Mui-focusVisible {
    background-color: transparent;
  }
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-17w5kqf-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  &::placeholder {
    font-size: 14px !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
}

.submit__handler-btn-cont {
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px !important;
    min-width: 160px !important;
    border-radius: 6px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
}

.c-pagination > nav > ul > li > button {
  border-radius: 0 !important;
}

.c-btn {
  display: flex !important;
  padding: 12px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  background-color: #402080 !important;
  min-width: 160px !important;
  height: 48px !important;
}

.tvShowModalInput > div > div > div {
  height: 50px;
}

.tvShowModalInput > div > div > div > div {
  margin-left: 20px;
}

.create__btn {
  width: auto !important;
  min-width: 160px !important;
  height: 48px !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  font-family: $primary-font !important;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.text__decoration-underline {
  text-decoration: underline;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.opacity-half {
  opacity: 0.5;
}

.table-def {
  box-shadow: none !important;
  border: none;

  .MuiTableCell-head {
    font-weight: $font-semi-bold;
    size: $paragraph-6;
    line-height: $paragraph-height-3;
    color: $primary_black;
    font-family: Inter, sans-serif;
  }

  .MuiTableCell-body {
    font-weight: $font-weight-400;
    size: $paragraph-6;
    line-height: $paragraph-height-3;
    color: $secondary_lgrey-darker;
    font-family: Inter, sans-serif;
  }
}

.widget-table-definition {
  .MuiTableCell-body {
    line-height: $heading-1 + 1px;
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.MUIBox__parent {
  position: relative;
  display: flex;
  justify-content: center;

  > .MuiBox-root {
    border: 1px solid rgba(25, 29, 35, 0.16);
  }

  &.locked {
    border: 1px solid #d4351c;
    border-radius: 8px;
  }

  .MUIBox__locked {
    height: 26px;
    border-radius: 4px;
    border: 1px solid var(--Accents_Red-Red, #d4351c);
    color: #d4351c;
    background: var(--Accents_Red-Shades-Light, #fbebe8);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    position: absolute;
    z-index: 9;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 0 10px;
  }
}

.word-wrap {
  word-wrap: break-word;
}

.heading-6 .MuiFormControl-root label span span.ml-1 {
  display: flex;
  margin-left: 0 !important;
}

.drawer-btn-group {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
  padding-right: 16px;
  margin-left: 0;
  width: 100%;
}

.parent-drawer-btn-group {
  padding-bottom: 40px;
}

.MuiFormControl-root {
  .MuiInputBase-root {
    .MuiInputBase-input.MuiSelect-select {
      padding: 12.5px 32px 12.5px 14px;
    }

    .MuiInputBase-input {
      padding: 13.95px 14px;
    }
  }

  .MuiCheckbox-root {
    &.Mui-disabled.Mui-checked {
      color: rgb(64 32 128 / 40%);
    }
  }
}

.MuiGrid-container.cus_container {
  column-gap: normal !important;
}

.textEllipsesLine-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.textEllipsesLine-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.textEllipsesLine-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing-filter {
  gap: 10px;

  .listing-filter-status {
    min-width: 150px;

    .MuiOutlinedInput-root {
      padding-right: 20px;
    }
    .c-drop-down-pagination {
      font-size: 14px !important;
      color: #6a6c6d !important;
    }
  }
}

.search-input {
  input {
    padding-left: 0 !important;
  }
}

.tab-c > div {
  height: 43px !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.custom-input-h-48 {
  .MuiFormLabel-root {
    svg {
      height: 20px;
    }
  }

  .MuiOutlinedInput-input {
    height: 0.9375em;
  }
}

.max-height-260 {
  max-height: 260px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3.5px 4px 3.5px 5px !important;
  height: auto !important;
}

.MuiAutocomplete-root .MuiFormControl-root .MuiInputLabel-root {
  transform: translate(14px, 14px) scale(1);
  &.MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(0.75);
  }
}

.MuiFormControl-root .MuiInputLabel-root {
  transform: translate(14px, 14px) scale(1);
  &.MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(0.75);
  }
}

.content__padding {
  .MuiGrid-container {
    // .MuiGrid-item:nth-child(even) {
    //   padding-left: 59px;
    // }
    .MuiAutocomplete-root {
      .MuiFormLabel-root {
        top: -2px;

        &.MuiInputLabel-shrink {
          top: 0;
        }
      }
    }
  }
}

.p-30-22 {
  padding: 30px 22px !important;
}

.rolebox__header-padding {
  .MuiButtonBase-root {
    padding: 0 15px;
  }
}

.list-icon {
  padding: 6px;
  width: 28px;
  height: 28px;
  background: #f3f2f1;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

span.dashLine {
  border: 1px dashed #ccc;
  display: block;
  width: 80px;
  position: relative;
}

span.listing-dashLine,
span.listing-dashLine-first {
  border: 1px dashed #6a6c6d;
  display: block;
  width: 76px;
  position: relative;
  left: 16px;
}

span.dashLine-clone {
  width: 50px;
}

span.listing-dashLine::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 44px;
  border: 1px dashed #6a6c6d;
  top: -46px;
  left: -3px;
}

span.listing-dashLine::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6a6c6d;
  right: -1px;
  top: -4px;
}

span.listing-dashLine-first::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 34px;
  border: 1px dashed #6a6c6d;
  top: -36px;
  left: -3px;
}

span.listing-dashLine-first::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6a6c6d;
  right: -1px;
  top: -4px;
}

span.dashLine::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #888;
  right: 0;
  top: -4px;
}

span.dashLine::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 64px;
  border: 1px dashed #ccc;
  top: -64px;
  left: -3px;
}

span{
  &.listing-dashLine-first{
    &.progress-bar{
      top: -8px;
      &::before{
        top: -57px;
        height: 55px;
      }
    }
  }
}

span{
  &.dashLine-clone-progressBar{
    width: 61px;
  }
}

.widget-table-definition {
  span.dashLine::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 45px;
    border: 1px dashed #ccc;
    top: -44px;
    left: -4px;
  }
}

.video-media-info-pagination-select {
  .MuiSelect-select {
    display: flex;
    justify-content: space-between;
  }
}

.h-26px {
  height: 26px;
}

.text-capitalise {
  text-transform: capitalize;
}

.p-24p {
  padding: 24px;
}

.homepage-wrapper {
  padding: 24px;

  .card-wrapper {
    background-color: #ffff;
    padding: 1.2rem 1.2rem 2.6rem 1.2rem;
    border-radius: 20px;
  }

  .dashboard-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .card-padding {
    padding: 1rem 0.5rem 0px 1.5rem !important;
  }

  .column-gap-20 {
    column-gap: 20px;
  }

  .asset-status-slider {
    background-color: #fff;
    padding: 1.2rem 0px 1.2rem 1.2rem;
    border-radius: 20px;

    .slick-arrow.slick-prev {
      position: absolute;
      right: 4rem;
      left: auto;
      top: -32px;
      width: 36px;
      height: 36px;
      border: 1px solid #c3d3e2;
      border-radius: 4px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .slick-arrow.slick-disabled {
      opacity: 0.5;
    }

    .slick-prev:before,
    .slick-next:before {
      display: none;
    }

    .slick-track {
      margin-left: 0;
    }

    .slick-arrow.slick-next {
      position: absolute;
      right: 1rem;
      top: -32px;
      width: 36px;
      height: 36px;
      border: 1px solid #c3d3e2;
      border-radius: 4px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .box-item {
      padding: 20px;
      margin-right: 1.3rem;
      border-radius: 1rem;
      height: 220px;

      .status-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-bottom: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .status-val {
        font-weight: 700;
        color: #3e3f40;
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 12px;
      }

      .status-text {
        font-weight: 600;
        color: #505152;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .dashboard-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;

    .lootie-text {
      color: #3e3f40;
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 10px;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
  }

  .content-category-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 60%;

    .category-dropdown {
      .MuiAutocomplete-root {
        .MuiFormControl-root {
          .MuiInputBase-root {
            input::placeholder {
              color: rgba(0, 0, 0, 0.87) !important;
              opacity: 1;
              font-weight: 500;
              font-size: 14px;
            }

            height: auto;
            padding: 0px 0px 0px 9px;

            .MuiChip-label {
              padding: 1px 10px 1px 0px;
            }

            .MuiButtonBase-root {
              &.MuiChip-root {
                padding: 1px 0px 1px 0px;
                font-size: 14px;
              }
            }
          }
        }
      }

      fieldset {
        legend {
          width: 0;
        }
      }

      gap: 17px;
      position: absolute;
      right: 0;

      > label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #151d48;
        margin-right: 0;
        margin-top: 8px;
        white-space: nowrap;
      }

      .MuiInputBase-root {
        border-radius: 8px;
        min-width: 150px;
        max-width: 250px;
        max-height: 35px;
        position: relative;
        z-index: 99;
      }

      .MuiInputBase-input {
        height: 0;
      }
    }

    span {
      font-size: 14px;
      font-weight: 500;
      font-family: inter, sans-serif;
      display: flex;
      align-items: center;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }

    .category-list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 22px;

      li {
        list-style: none;
        display: flex;
        column-gap: 15px;

        &:nth-child(1),
        &:nth-child(4) {
          width: 40%;
        }

        &:nth-child(2),
        &:nth-child(5) {
          width: 40%;
        }

        &:nth-child(6),
        &:nth-child(3) {
          width: 20%;
        }

        span {
          width: 22px;
          height: 22px;
          display: inline-block;
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #6a6c6d;
        }
      }
    }
  }

  .recent-activity-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(200, 200, 200);
    border-radius: 5px;
    display: none;
  }

  .recent-activity-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 40%;
    overflow: auto;
    max-height: 600px;

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }

    .recent-activity-list {
      overflow: visible;
      // padding-right: 10px;
      border-left: 2px dashed #ddd;
      margin-top: 10px;
      margin-left: 8px;

      .activity-row {
        border-bottom: 2px dashed #ddd;
        padding: 10px 0;
        position: relative;
        cursor: pointer;

        &:first-child {
          padding-top: 0px;

          .icon-left {
            position: absolute;
            left: -8px;
            top: -3px;
          }
        }

        .icon-left {
          position: absolute;
          left: -8px;
          top: -3px;
        }

        .publised-id {
          font-size: 14px;
          line-height: 16px;
          color: #3e3f40;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          margin: 10px 0 6px;

          span {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .publised-text {
          font-size: 12px;
          line-height: 24px;
          font-weight: 500;
          color: #6a6c6d;
        }

        .publised-author {
          font-size: 12px;
          line-height: 24px;
          font-weight: 500;
          color: #3e3f40;
          display: flex;
          justify-content: space-between;

          span {
            font-weight: 400;
          }
        }
      }
    }
  }

  .asset-type-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 70%;

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .category-dropdown {
      > label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #151d48;
        margin-right: 12px;
      }

      .MuiInputBase-root {
        border-radius: 8px;
        width: 76px;

        .MuiSelect-select {
          min-height: auto;
          padding: 6px 12px 7px;
          width: 76px;
        }
      }
    }
  }

  .published-asset-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 30%;

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .publised-val {
      font-size: 24px;
      line-height: 34px;
      font-weight: 700;
      color: #3e3f40;
    }

    .category-dropdown {
      > label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #151d48;
        margin-right: 12px;
      }

      .MuiInputBase-root {
        border-radius: 8px;
        width: 121px;

        .MuiSelect-select {
          min-height: auto;
          padding: 6px 12px 7px;
          width: 121px;
        }
      }
    }
  }
}

.in-progress {
  animation: rotate 1s linear infinite;
  display: inline-block;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.global-search-input {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  height: 40px !important;
  border-radius: 6px 0px 0px 6px;
  background-color: #fff !important;

  div {
    height: 100% !important;
    border-radius: 6px 0px 0px 6px;
  }

  ::placeholder {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #545454 !important;
  }
}

.advanced-search {
  display: flex;

  .global-search-input {
    border-radius: 6px;
    .MuiOutlinedInput-root {
      border: none;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .Mui-focused {
      border: none;
      outline: none;
    }
    .MuiInputAdornment-outlined {
      outline: none;
      border: none;
      &:hover,
      &:focus {
        outline: none;
        border: none;
      }
    }
  }
  .MuiFormControl-root {
    .MuiFormLabel-root {
      top: -7px;

      &.MuiInputLabel-shrink {
        top: 0;
        z-index: 9;
      }
    }

    .MuiInputBase-root {
      border-radius: 6px;
      width: 302px;

      .MuiInputBase-input {
        height: auto;
        padding: 8px 16px 9px 8px;
        background: #fff;
        // border-top-left-radius: 6px;
        // border-bottom-left-radius: 6px;
        border-radius: 6px;
      }
    }
  }

  .search-advanced-btn {
    height: 40px;
    background-color: #ece9f2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-left: 3px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 40px;
    cursor: pointer;
  }
}

.metaattributes-fields {
  width: calc(100% - 50px);
}
.delete-icon {
  position: absolute;
  right: 0;
  top: 35px;

  @media (min-width: 1200px) and (max-width: 1300px) {
    body {
      zoom: 0.9;
    }
  }

  @media (min-width: 1301px) and (max-width: 1400px) {
    body {
      zoom: 0.95;
    }
  }

  .JSON__dialog-width {
    width: 500px;
  }

  .table-link:link {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }

  .table-link:visited {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }

  .table-link:hover {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }

  .table-link:active {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }
}

.border__box_width_hr {
  border: 1px solid rgba(25, 29, 35, 0.16);
}

.filter__width {
  width: 200px;
}

@media (min-width: 1301px) and (max-width: 1400px) {
  body {
    zoom: 0.95;
  }
}

.JSON__dialog-width {
  width: 500px;
}

.table-link:link {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.table-link:visited {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.table-link:hover {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.table-link:active {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.custom-muiAutocomplete {
  .MuiFormLabel-root {
    top: -3px;

    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .MuiAutocomplete-root {
    .MuiInputBase-root {
      gap: 3px;
      padding: 8px 50px 8px 8px;

      .MuiChip-root {
        margin: 0;
      }
    }
  }
}

.media-wrapper {
  .rolebox__header-padding {
    border-bottom: 1px solid rgba(177, 180, 182, 0.5);
  }

  .MuiAccordionDetails-root {
    padding: 12px 9px;

    .MuiGrid-container {
      > .MuiGrid-item.MuiGrid-grid-xs-12 {
        > .MuiGrid-container {
          > .MuiGrid-grid-xs-12.MuiGrid-item:first-child {
            border-bottom: 1px solid #e7e8e8;
          }
        }
      }
    }
  }

  div[class*='AssetEditor'].MuiGrid-item {
    &:first-child {
      padding-top: 42px !important;
    }

    padding-top: 16px !important;
  }

  .MuiAccordion-rounded {
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 8px !important;

    .MuiAccordionSummary-root {
      border-bottom: none;
      min-height: 41px;

      &.Mui-expanded {
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        min-height: 41px;
      }

      .MuiAccordionSummary-content {
        margin: 8px 0;
      }
    }
  }
}

.h-28 {
  height: 28px;
}

.custom-chip-wrap {
  .MuiStack-root {
    max-width: 210px;

    .MuiChip-root {
      height: 24px;
    }
  }
}

.line-wrap {
  width: 40ch;
  text-wrap: wrap;
  word-break: break-all;
}

.vjs-workinghover {
  width: 100% !important;

  video {
    width: 100% !important;
    aspect-ratio: 16 / 9 !important;
  }
}

.video-js {
  padding-top: calc(100% - 300px) !important;
}

.graph-tooltip {
  position: relative;

  &:before {
    content: '';
    width: 15px;
    height: 20px;
    border-right: solid 10px #3e3f40;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent;
    left: -15px;
    top: 35%;
    position: absolute;
  }
}

.add-column-container {
  height: 41px;
  padding: 10px 12px 8px 12px;
  border-radius: 6px;
  border: 1px solid #b1b4b6;
  text-align: flex-end;

  .arrow-icon {
    margin-top: 6px;
    color: #6a6c6d;
  }
}

.font-14 {
  font-size: 14px;
}

.refresh-icon {
  width: 48px;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

.segment-id-wrapper {
  width: 50%;

  > .MuiAutocomplete-root {
    > .MuiFormControl-root {
      > .MuiInputBase-root {
        height: 48px;
      }
    }
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.MuiFormHelperText-root {
  white-space: pre-line;
}

.d-none {
  display: none;
}

.dialog__padding-horizontal {
  padding-left: 24px;
  padding-right: 24px;
}

.dialog__padding-vertical {
  padding-top: 24px;
  padding-bottom: 24px;
}

.dialog__overflow {
  max-height: 35vh;
  overflow: auto;
}

.header-logo {
  svg {
    margin-top: 2px;
  }
}

.segment-section-heading {
  font-weight: 500;
}

.or-seperator {
  height: 19px;
  &::before {
    display: flex;
    width: 100%;
    height: 1px;
    background: #e7e8e8;
    content: '';
    position: relative;
    top: 9px;
  }
  span {
    display: inline-flex;
    background: #fff;
    color: #191d23;
    font-size: 18px;
    line-height: 19px;
    font-weight: 500;
    position: relative;
    z-index: 99;
    padding: 0 10px;
    top: -3px;
  }
}

.platform-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    cursor: pointer;
  }
}

.clone-container {
  padding: 24px;
  max-width: 400px;
}

.clone-header {
  margin-bottom: 15px;
  h3 {
    font-size: 18px;
    font-weight: 500;
  }
}
.clone-icon {
  display: inline-flex;
  border: 10px solid #e2deec;
  border-radius: 100%;
  margin-bottom: 10px;

  svg {
    border-radius: 100%;
  }
}

.clone-body {
  h5 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 16px;
  }
}

.clone-form-group {
  margin-bottom: 20px;
}

.clone-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    min-width: inherit;
    width: calc(50% - 10px);
  }
}

.cloned-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 20px 0 10px;
  color: #3e3f40;
  border-bottom: 1px solid #f3f4f4;
}

p {
  &.cloned-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #6a6c6d;
  }
}

.clone-icon {
  border: 10px solid #e2deec;
}

.clone-list {
  border-bottom: 1px solid #f3f4f4;
  padding: 10px 5px;
  a {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: calc(100% - 40px);
    }
  }
}

.cloned-btn {
  padding: 9px 15px;
  height: 44px;
}

.details-inner-header {
  padding: 1.5rem 1rem;
  color: #191d23;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  &.drawer {
    padding: 1.5rem 0;
  }
}

.definition-accordian-heading {
  .heading-6 {
    text-transform: capitalize;
  }
}

.search-full-width {
  .search-container {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
}

.drag-icon-container {
  padding: 4px;
  background: #ece9f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  svg {
    color: #402080;
    width: 20px;
    height: 20px;
  }
}
.dragable-title {
  h6 {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: #3e3f40;
  }
}
.suffleContainer {
  height: 28px;
  padding: 0 3px;
  .MuiIconButton-sizeMedium {
    padding: 0;
    width: 11px;
    height: 11px;
    margin-right: 4px;
  }
  .MuiInputBase-input {
    height: 22px;
    font-size: 14px;
    font-weight: 600;
  }
}
.asset-number-input {
  & > div {
    width: 32px;
    height: 27px;
    margin-top: 1px;
  }
  input {
    height: 100%;
    height: 100%;
    background: #fff;
    padding: 0 3px !important;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #402080;
    opacity: 0.75;
  }

  fieldset {
    border: 0;
  }
}
.click_disable {
  pointer-events: none;
}
.edit-icon-wrap {
  width: 28px;
  height: 28px;
}
.delete-icon-wrap {
  width: 28px;
  height: 28px;
  padding: 0;
}
.drop-down-wrap.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
  background-color: transparent !important;
  &:hover {
    background-color: transparent;
  }
}

.drop-down-wrap {
  padding: 0 !important;
}
.custom_accordion {
  overflow: hidden;
  .accordion-details {
    padding-right: 10px;
    padding-left: 10px;
    background: #f5f4f4;
  }
  .image-node-parent {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .image-node-filter {
    width: 25%;
    background: #ffffff;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.accordion-view-page {
  button,
  input,
  svg {
    pointer-events: none;
    cursor: default;
  }
  button,
  .drag-icon-container {
    opacity: 0.5;
  }
  .suffleContainer {
    opacity: 0.5;
    button {
      opacity: 1;
    }
  }
  .drop-down-wrap {
    opacity: 1;
  }
}

.assets-filter-group {
  label {
    color: #3e3f40 !important;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
  }
}

.asset-radio-group {
  font-family: Inter, sans-serif;
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  label {
    margin-right: 40px;
    margin-left: 0;
    color: #6a6c6d;
    & > span {
      color: #6a6c6d;
      padding: 0;
      margin-right: 5px;

      &.Mui-checked {
        color: #402080;

        & + span {
          color: #402080;
        }
      }
    }
    .css-1hbvpl3-MuiSvgIcon-root {
      font-size: 1.4rem;
    }
    .css-11zohuh-MuiSvgIcon-root {
      font-size: 1.4rem;
    }
  }
}
.faded_parent_widget_list {
  opacity: 0.6; /* 40% faded */
  // pointer-events: none; /* Disable clicks, dragging, etc. */
  user-select: none; /* Disable text selection */
  padding: 10px;
}
.replace-asset-radio {
  input {
    opacity: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 2;
    & ~ label {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #8c8780;
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #402080;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }
    &:checked + label {
      border-color: #402080;
      &:before {
        opacity: 1;
      }
    }
  }
}
.selected-row {
  background: rgba(236, 233, 242, 1);
}
.disabled-row {
  background: #e7e8e8;
  color: #ece9f2;
  opacity: 0.5;
  pointer-events: none;
  &:hover {
    background: #e7e8e8;
  }
  td {
    color: #3e3f40;
    .replace-asset-radio,
    input {
      display: none;
    }
  }
}
#panel1a-header {
  .css-f4gwwp {
    margin-bottom: 0.4rem;
  }
}
.content-body-text {
  .label {
    color: #505152;
    font-size: 12px;
  }
  strong {
    color: #3e3f40;
    font-weight: 500;
    font-size: 12px;
  }
}

.AssetStateLabel {
  &.Published {
    background-color: #d9e6e0;
    color: #005a30;
    height: 24px;
    padding: 0 9px;
    display: inline-flex;
  }
}

.MuiButtonBase-root {
  &.MuiCheckbox-root {
    color: #b1b4b6;
    .MuiSvgIcon-root {
      font-size: 1.5rem;
    }
  }
}
.DataCategory_engineViewWrapper__ssU5b {
  .css-1om8dep-MuiPaper-root-MuiAccordion-root {
    overflow: hidden;
  }
}

.assets-position-select {
  width: 69px;
  height: 36px;
  color: #402080;
}

.filter-grey {
  background: #f7f8f8;
  padding: 16px;
  border-radius: 8px;

  .asset-dropwon-group {
    width: 100%;
    max-width: 400px;
    margin-top: 15px;
  }
}

.asset-filter-dropdown {
  background: #fff;
}

.asset-modal {
  .shadow-table {
    border: 1px solid #e3e3e3;
    margin-top: 20px;

    table {
      th,
      td {
        padding: 12px !important;

        .paragraph-regular-6 {
          & > span {
            padding: 0;
          }
        }
      }
    }
  }
  .listing-container {
    background: #fff;
    padding: 0;
  }
  table {
    td {
      padding: 10px;
    }
  }
  .selected-accordion-table {
    background: #f5f4f4;
    padding: 1rem;

    .shadow-table {
      margin-top: 0;
    }
  }
}
.feature-later-container {
  padding: 0.75rem 0 0.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

.feature-later-header {
  display: flex;
  padding: 0 1rem 0.75rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e8e8;
  margin-bottom: 1.5rem;

  .alarm-icon-container {
    width: 1.75rem;
    height: 1.75rem;
    background: #ece9f2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #191d23;
    margin: 0;
  }
}

.feature-later-buttonContainer {
  display: flex;
}
.text-capital {
  text-transform: capitalize;
}
.feature-later-chipButton {
  margin-right: 8px;
  padding: 0px 8px;
}

.feature-later-deleteButton {
  color: #d4351c;
  padding: 0px 8px;
}

.feature-later-section {
  padding: 0.75rem 0 0.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

.feature-later-row {
  display: flex;
  padding: 0 1rem 0.75rem;
  align-items: center;
}

.feature-later-label {
  width: 350px;
  white-space: nowrap;
}

.feature-later-value {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.feature-later-modal-container {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 450px;
  height: auto;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 12px;
}

.feature-later-modal-icon {
  background-color: #ffebee;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.feature-later-modal-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: left;
}

.feature-later-modal-description {
  font-size: 14px;
  color: #5f6368;
  margin-bottom: 1.5rem;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
}

.feature-later-modal-actions {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  gap: 16px;
}

.footer {
  position: absolute;
  width: -webkit-fill-available;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  height: 66px;
  z-index: 10;

  .submit-btn-wrapper {
    width: calc(100vw - 18.5rem);
    padding-right: 1rem;

    .submit-btn {
      width: min-content;
      float: right;
      padding-top: 15px;
    }
  }
}
.asset-tab-container {
  margin-top: 12px;
  border-bottom: 1px solid rgba(177, 180, 182, 0.5);
  .asset-tabs {
    min-height: 38px;

    button {
      min-width: 130px;
      min-height: 38px;
      padding: 10px 16px;
      border-radius: 4px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  & + .filter-grey {
    border-radius: 0 0 8px 8px;
  }
}

.listing-container {
  .search-background {
    background: #f7f8f8;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    margin-top: -1.2rem;
  }
}

.engine-radio-group {
  .replace-asset-radio {
    label {
      margin-right: 10px;
    }
  }
}

.c-select {
  .c-select-menu-item {
    height: 48px;
  }
}

// Audit Trails Start from here
// Tab button css
.tab-btn-wrapper {
  display: flex;
  .tab-btn {
    padding: 0 25px;
    height: 38px;
    border: none;
    border-bottom: 2px solid transparent;
    background-color: #fff;
    font-size: 14px;
    color: #3e3f40;
    font-weight: 500;
    min-width: 144px;
    cursor: pointer;
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active {
      color: #402080;
      border-bottom-color: #402080;
      background-color: #ece9f2;
    }
  }
  + .listing-container {
    padding-top: 0;
    .shadow-table {
      margin-top: 0;
      table {
        th {
          padding: 10px 12px !important;
          background-color: #e7e8e8;
        }
        tbody {
          tr {
            &:nth-child(even) {
              td {
                background-color: #f7f8f8;
              }
            }
          }
        }
      }
    }
  }
}

.audit-details-wrapper {
  .list-wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: #f7f8f8;
    border-radius: 8px 8px 0 0;
  }
  .list {
    width: calc(100% / 3);
    padding: 7px 15px;
    display: flex;
    align-items: center;
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:nth-child(3) {
      border-radius: 0 8px 8px 0;
    }
    &:nth-child(-n + 3) {
      padding: 20px 15px;
      background-color: #fff;
    }
    .label {
      font-size: 14px;
      color: #6a6c6d;
      margin-right: 15px;
      line-height: 26px;
    }
    .value {
      font-size: 14px;
      color: #3e3f40;
      margin-right: 15px;
      line-height: 26px;
      font-weight: 500;
      display: flex;
      align-items: center;
      .status {
        margin-left: 16px;
      }
    }
  }
}
.px-40 {
  padding: 0 40px;
}
.px-24 {
  padding: 0 24px;
}
.px-16 {
  padding: 0 16px;
}
.pt-16 {
  padding-top: 16px;
}
.global-search-page {
  .listing-container {
    padding-top: 0;
  }
}

.audit-details-page-wrapper {
  .box {
    border-radius: 8px;
    margin-bottom: 30px;
    overflow: hidden;
    .list-header {
      padding: 12px 20px;
      font-size: 14px;
      line-height: 24px;
      color: #191d23;
      background-color: #fff;
      font-weight: 600;
    }
    .list-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #f7f8f8;
      padding: 0 10px;
      .list {
        width: calc(50% - 5px);
        padding: 13px 10px;
        display: flex;
        align-items: center;
        border-top: 1px solid #f0f0f0;
        &:nth-child(-n + 2) {
          border: none;
        }
        .label {
          font-size: 14px;
          color: #6a6c6d;
          margin-right: 15px;
          line-height: 26px;
          min-width: 206px;
        }
        .value {
          font-size: 14px;
          color: #3e3f40;
          margin-right: 15px;
          line-height: 26px;
          font-weight: 500;
          display: flex;
          align-items: center;
          .status {
            margin-left: 16px;
            color: #cc8f00;
            font-size: 12px;
            height: 24px;
            width: 48px;
            border-radius: 4px;
            background-color: #fff7e6;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .list-data {
      padding: 0 20px;
      font-size: 14px;
      line-height: 26px;
      color: #3e3f40;
      &.middle {
        padding: 15px 20px;
      }
      .list-item {
        border-bottom: 1px solid #f0f0f0;
        padding: 15px 0;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .box-top {
    border: 1px solid #00000029;
  }
  .box-middle {
    .list-header {
      border-bottom: 1px solid #e7e8e8;
    }
    .list-data {
      background-color: #fff;
    }
  }
  .box-bottom {
    .list-data {
      background-color: #d9e6e0;
    }
  }
}
.duration-dropdown {
  top: 56px !important;
  .duration-layout-root {
    display: flex;
    flex-direction: row;
    .duration-wrapper {
      display: flex;
      flex-direction: column;
      .duration-time {
        overflow: hidden;
        margin: 0px auto;
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px;
        ul {
          overflow-y: scroll;
          scroll-behavior: auto;
          max-height: 232px;
          li {
            cursor: pointer;
            margin: 5px;
            padding: 5px;
            text-align: center;
            &:last-child {
              margin-bottom: 120px;
            }
          }
          li:hover {
            background-color: rgba(64, 32, 128, 0.04);
          }
          li.selected {
            background-color: rgb(64, 32, 128);
            color: rgb(255, 255, 255);
          }
        }
      }
    }
  }
  .duration-action {
    display: flex;
    justify-content: end;
    padding: 5px;
  }
}

.clear-all {
  font-weight: 500;
  color: #402080;
  cursor: pointer;
  font-size: 12px;
}

.clear-all-disabled {
  font-weight: 500;
  color: #402080;
  font-size: 12px;
  opacity: 0.5;
}

input[type='radio']:checked::before {
  background: #402080;
  content: '';
  display: block;
  position: relative;
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #402080;
}
input[type='radio']:checked {
  background: #402080;
  border: 1px solid #402080;
}

.version-clone-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 24px;
  &.flex-start {
    justify-content: flex-start;
  }
}

.version-icon {
  width: 27px;
  height: 28px;
  background: #f3f2f1;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  span {
    width: 12px;
    height: 12px;
    background: #d4351c;
    color: #fff;
    border-radius: 100%;
    position: absolute;
    top: -4px;
    right: -6px;
    font-size: 8px;
    line-height: 10px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.listing-edit-icon {
  width: 27px;
  height: 28px;
  background: #f3f2f1;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clone-icons {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: #f3f2f1;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 0;
    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.dashLine-cell {
  background: #f5f4f4;
  width: 0;
  padding-left: 0;
  border: 0;
  padding-right: 0px !important;
  border-bottom: 1px solid #f5f4f4 !important;
}

.asset-stepper-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.MuiAccordion-rounded {
  .MuiAccordionSummary-root {
    border-bottom: 0;
  }
}

nav {
  &.pagination-container {
    ul {
      border-color: #b1b4b6;
      li {
        border-left-color: #b1b4b6;
      }
    }
  }
}
.add-asset-btn {
  white-space: nowrap;
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.preview-image-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      min-width: 388px;
    }
  }
}
.audit-container-list,
.list-filter-data {
  + .listing-container {
    .shadow-table {
      margin-top: 5px;
    }
    .MuiBox-root {
      padding-top: 0;
    }
  }
}
.associate-list {
  margin-bottom: 8px;
}

.Mui-error {
  margin-left: 0 !important;
}

.MuiBox-root {
  .clone-modal-text {
    text-transform: none;
  }
}
.list-filter-data {
  padding: 24px 24px 0;
  h3 {
    width: 100%;
    font-size: 14px;
    color: #111827;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .filter-inner {
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    margin: 0;
    width: 100%;
    gap: 20px;
  }
  .item-50 {
    width: calc(50% - 10px);
    padding: 0;
  }
  .btn {
    height: 48px;
    border-radius: 8px;
    &.primary-btn {
      width: 160px;
      background-color: #402080;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    &.border-btn {
      width: 148px;
      color: #344054;
      background-color: #fff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      font-weight: 500;
    }
  }
  .btn-wrapper {
    margin-top: 0;
  }
  .layout-wrap {
    width: 100%;
  }
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding: 5px 9px;
      height: 48px;
    }
  }
}
.MuiMenu-list {
  .MuiMenuItem-root {
    padding: 10px 16px;
  }
}

.emptyblock__table {
  height: 60px;
  padding-top: 18px;
  align-content: center;
}

.metadata {
  font-size: 14px;
  color: #6a6c6d;
  font-weight: 400;
  span {
    color: #3e3f40;
    font-weight: 500;
    margin-left: 100px;
  }
}
.behaviour-category-fields {
  min-height: 3.25rem;
  flex-wrap: wrap;
  align-items: stretch;
}
.feature-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .feature-delete-btn {
    height: 44px;
    width: calc(50% - 6px);
    color: #344054;
  }
  .feature-confirm-btn {
    height: 44px;
    width: calc(50% - 6px);
  }
  &.feature-btn-full {
    width: 100%;
    justify-content: center;
    .feature-delete-btn {
      width: 100%;
    }
  }
}
.assets-position-select {
  .MuiSelect-select {
    position: relative;
    &[aria-expanded='true'] {
      &:after {
        transform: rotate(180deg) translateY(50%);
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      background: url('./Assets/Images/down-arrow.svg') no-repeat center center;
      width: 10px;
      height: 10px;
      transition: all 0.25s;
    }
  }
  .MuiSelect-icon {
    display: none;
  }
  fieldset {
    top: 0;
  }
  legend {
    display: none;
  }
}
.feature-btn {
  width: 50%;
  &.MuiButton-root {
    height: 50px;
  }
}
.feature-later-btn {
  padding-top: 2rem;
  gap: 2.25rem;
  width: 100%;

  button {
    width: 50%;
    height: 50px;
  }
}

.image-accordion-container {
  border: 1px solid #00000029;
  border-radius: 8px;
  box-shadow: none;
  margin-bottom: 22px;

  &.MuiAccordion-root {
    border-radius: 8px;
    margin-bottom: 22px;
    box-shadow:
      0px 1px 0px -1px rgba(0, 0, 0, 0.2),
      0px 0px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  &.error {
    border-color: #d4351c;

    .image-validation {
      color: #d4351c;
    }
  }

  &.warning {
    border-color: #bf8600;

    .image-validation {
      color: #bf8600;
    }
  }
}

.image-listing-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.image-validation {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.data-category-container {
  .asset-container {
    & + .asset-container {
      margin-top: 10px;
    }
  }
}

.data-category-form {
  width: 100%;
  margin-bottom: 1.5rem;
}

.asset-container {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 6px;

  .asset-title {
    font-size: 14px;
    font-style: normal;
    display: flex;
    font-weight: 600;
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    margin-bottom: 16px;
    justify-content: space-between;
  }
  .asset-btn-container {
    padding: 0 16px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 27px;
  }
}

.seperator-hr {
  opacity: 0.5;
}

.popup-strong-text {
  font-weight: bold;
  color: #3e3f40;
  display: inline-block;
}

.row-title-value {
  min-height: 3.25rem;
  padding: 1rem 0.75rem;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.ck-editor__editable {
  font-weight: normal;
  padding: 40px;
  font-size: 14px;
  ul,
  ol {
    margin: 0;
    padding-left: 20px;

    li {
      margin-bottom: 5px;
    }
  }
}

.content-container {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;

  ul,
  ol {
    padding-left: 20px;
    // list-style-type: disc;

    li {
      margin-bottom: 4px;
    }
  }
}
.filter-popover {
  li {
    span {
      &.MuiCheckbox-root {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
}
.field-listing-view {
  flex-wrap: wrap;
  align-items: stretch;
}

.field-listing-content {
  display: flex;
  align-items: baseline;
  min-height: 3.25rem;
  width: 50%;
  padding: 1rem 0.75rem;
  font-size: 14px;
}
.complex-type-field-title {
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 0.75rem;
}


.graph-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: 'column';

  .MuiResponsiveChart-container > svg {
    width: 400px !important; 
    height: 400px !important; 
    margin: 0 auto;
  }
}

.graph-legend {
  &.MuiStack-root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; 

    & > div {
      width: 32%; 
      margin: 0; 
      text-align: left; 
      &:nth-of-type(2n){
        width: 46%;
      }
      &:nth-of-type(3n){
        width: max-content
      }
      &:nth-of-type(4n){
        width: 32%;
      }
      &:nth-of-type(5n){
        width: 46%;
      }
    }
  }
}
.associatedListSearch{
  padding: 47px 24px 0 24px;
}
