.progress-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 359px;
  padding: 20px 0 15px;
  position: relative;
  top: -8px;
  background: #fff;
  border-radius: 8px 8px 0 0;
  margin-bottom: 20px;
}

.progress-step {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  z-index: 2;
  border: 1px solid rgb(201, 200, 200);
  background-color: white;
}


.circle_success {
  background-color: #402080;
}

.circle_error {
  border: none;
  background-color: #D4351C;
}

.circle._warning {
  border: none;
  background-color: #f1c40f;
}

.circle_pending {
  background-color: white;
}

.circle_next_success {
  border: 1px solid #402080;
}

.progressBarIcon{
 display: flex;
 align-items: center;
 justify-content: center;
}


.dot {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: rgb(201, 200, 200);
  border-radius: 50%;
}

.dot_next_success_dot {
  background-color: #402080;
}

.label {
  width: 100%;
  font-size: 12px;
  color: #555;
  text-align: center;
  position: absolute;
  top: 29px;
  padding: 5px 0 10px 0;
  white-space: nowrap;
  background: #fff;
}

.progress-step:first-child .label {
  border-radius: 0 0 0 8px;
}

.progress-step:last-child .label {
  border-radius: 0 0 8px 0;
}

.line {
  position: absolute;
  top: 15px;
  left: 30px;
  width: 100%;
  height: 1.5px;
  z-index: 1;
  background-color: rgb(201, 200, 200);
}

.line_success-line {
  background-color: #402080;
}