.page_component {
  padding: 0 40px 22px;

  .back_button_advance_search {
    margin-top: 2rem;
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: space-between;
  }

  .MUI_BOX_Search {
    width: 100%;
    height: auto;

    .heading_padding {
      padding: 0.75rem 22px 1rem;
      border-bottom: 1px solid #e7e8e8;
    }

    .form_container {
      padding: 2.25rem 22px 6px;

      .form_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 41px;
        margin-bottom: 1.5rem;
        gap: 114px;

        .form_label {
          width: 350px;
          min-width: 350px;
          text-align: left;
        }

        .featured_date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 59px;
        }
      }
    }

    .advance_search_submit {
      float: right;
      padding-bottom: 40px;
      padding-right: 63px;
    }
  }
}

.listing_component {
  padding: 0 1rem;
}
.checkbox_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkbox_parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
