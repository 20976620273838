// Padding classes
.p-4 {
  padding: 4px 4px;
}
.p-8 {
  padding: 8px 8px;
}
.p-12 {
  padding: 12px 12px;
}
.p-16 {
  padding: 16px 16px;
}
.p-20 {
  padding: 20px 20px;
}
.p-24 {
  padding: 24px 24px;
}
.p-28 {
  padding: 28px 28px;
}
.p-32 {
  padding: 32px 32px;
}
.p-36 {
  padding: 36px 36px;
}
.p-40 {
  padding: 40px 40px;
}

.py-4 {
  padding: 4px 4px;
}
.py-8 {
  padding: 8px 8px;
}
.py-12 {
  padding: 12px 12px;
}
.py-16 {
  padding: 16px 16px;
}
.py-20 {
  padding: 20px 20px;
}
.py-24 {
  padding: 24px 24px;
}
.py-28 {
  padding: 28px 28px;
}
.py-32 {
  padding: 32px 32px;
}
.py-36 {
  padding: 36px 36px;
}
.py-40 {
  padding: 40px 40px;
}

.margin-h-60 {
  margin: 0px 60px 0px 60px;
}

// Border Radius classes
.border-4 {
  border-radius: 4px;
}
.border-8 {
  border-radius: 8px;
}
.border-6 {
  border-radius: 6px;
}
.border-12 {
  border-radius: 12px;
}
.border-16 {
  border-radius: 16px;
}
.border-20 {
  border-radius: 20px;
}
// shadow classed
.shadow-dark-bg {
  opacity: 15%;
  box-shadow: 0px 1px 4px 0px #383f43;
}
.shadow-light-bg {
  opacity: 8%;
  box-shadow: 0px 20px 24px -4px #383f43;
}
.shadow-table {
  box-shadow: 0px 20px 24px -4px rgb(56 63 67 / 8%);
}
// alert style
.alert-root {
  width: 400px;
  height: 340px;
  position: absolute;
  top: 866px;
  left: 98px;
  padding: 24px;
  border-radius: 12px;
  gap: 32px;
}
.cursor-pointer {
  cursor: pointer;
}
// flex classes
.d-flex {
  display: flex;
}
.d-inline-block {
  display: inline-block;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: start;
}
.align-items-end {
  align-items: end;
}
.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-stretch {
  align-items: stretch;
}

.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-cente {
  align-self: center;
}
.align-self-baseline {
  align-self: baseline;
}
.align-self-stretch {
  align-self: stretch;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.bg-red {
  background-color: red;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-19 {
  margin-top: 1.9rem;
}
.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}
.ml-5 {
  margin-left: 5rem;
}

.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}
.mr-5 {
  margin-right: 5rem;
}

.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}
.pt-4 {
  padding-top: 4rem;
}
.pt-5 {
  padding-top: 5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}
.pb-4 {
  padding-bottom: 4rem;
}
.pb-5 {
  padding-bottom: 5rem;
}

.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}
.pl-4 {
  padding-left: 4rem;
}
.pl-5 {
  padding-left: 5rem;
}

.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}
.pr-4 {
  padding-right: 4rem;
}
.pr-5 {
  padding-right: 5rem;
}
.px-2 {
  padding: 2rem;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}

.w-100 {
  width: 100%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-25 {
  width: 25%;
}
