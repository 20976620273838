.backBtnWrapper {
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .leftAerrow {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 6px;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    color: #111827;
  }
}
