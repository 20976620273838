.editor {
    width: calc(100% - 31.625rem);
    margin: 0 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #fff;
    height: fit-content
}
.editor_header {
    padding: 0.75rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16)
}