@import '../../../Assets/Styles/colors.scss';
@import '../../../Assets/Styles/typography.scss';

.masters__editor {
  position: relative;
  height: auto;
  overflow-y: auto;
  .masters__editor_wrapper {
    display: flex;
    width: 100%;
    .masters__editor_content {
      width: calc(100% - 280px);
      .hr {
        height: 2px;
        width: 100%;
        background-color: $border-bottom-grey;
      }
      .mastersbox__content_padding {
        padding: 2.5rem 1.2rem 1.2rem 1.2rem;
        .mastersbox__content_padding {
          padding: 1.25rem 0.75rem;
        }
      }

      .masters__box__header__padding {
        padding: 1.2rem;
      }

      .tip__box {
        border: 1px dashed $accent_yellow;
        background-color: $accent_yellow-light;
        padding: 1.5rem 1rem;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        .masters__box__header__padding {
          padding: 1.2rem;
        }
      }
    }
  }
}

.submit__handler {
  background-color: $white;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 15px;

  .submit__handler_btn_cont {
    width: calc(100vw - 350px);
    text-align: right;
  }
}
