.statusActive {
  display: inline-flex !important;
  height: 24px !important;
  width: auto !important;
  padding: 0px 9px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  color: #005a30 !important;
  background-color: #e6efea !important;
  font-family: Inter, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border-radius: 4px !important;
}
.statusInactive {
  display: inline-flex !important;
  height: 24px !important;
  width: auto !important;
  padding: 0px 9px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  color: #3e3f40 !important;
  background-color: #ddddde !important;
  font-family: Inter, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border-radius: 4px !important;
}
.mainDrawer {
  padding: 1.5rem;
  .drawerHeading {
    color: #383f43;
    font-size: 1.25rem;
  }
  .attributeBtn {
    text-transform: capitalize;
    margin: 2rem 0.5rem 4rem 0;
  }
  .attributeCreateBtn {
    margin: 0 0 0 0.438rem;
  }
  .activeBtn {
    background-color: #e8e8e9;
  }
}
